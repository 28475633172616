import React from "react"
import Layout from '../layout/layout2'
import { SectionImage, Container, SectionImageMobile } from "../utils/utils"

const Page404 = ({ data }) => {

  const img = data.img.childImageSharp.fluid
  const imgmob = data.imgmob.childImageSharp.fluid

  return <div>
    <Layout>
      <SectionImage fluid={img}>
        <Container>
          <h1>404</h1>
          <h6><span>Niestety szukana strona nie istnieje</span></h6>
        </Container>
      </SectionImage>

      <SectionImageMobile fluid={imgmob}>
        <Container>
          <h1>404</h1>
          <h6><span>Niestety szukana strona nie istnieje</span></h6>
        </Container>
      </SectionImageMobile>
    </Layout>
  </div>
}
export default Page404

export const query = graphql`
query {

  img: file(relativePath: { eq: "main_image2.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 2560) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  imgmob: file(relativePath: { eq: "main_image_mob.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 760) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
}`
